import React from "react";
import logo from "./img/logo.png";

const Header = () => {
  return (
    <div className="header">
      <img
        src={logo}
        alt="Coker Classic Star Series Logo"
        className="header__image"
      />
    </div>
  );
};

export default Header;
