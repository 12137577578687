import Footer from './Footer';
import Form from "./Form";
import Header from "./Header";

function App() {
  return (
    <div className="app">
      <Header />
      <Form />
      <Footer />
    </div>
  );
}

export default App;
