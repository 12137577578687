import { useState, useEffect } from "react";
import axios from "axios";

const Form = () => {
  const [file, setFile] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [year, setYear] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [consent3, setConsent3] = useState(false);
  const [inputKey, setInputKey] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [message]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setStatus("error");
      setMessage("Please upload an image");
      return;
    }

    if (
      !firstName ||
      !lastName ||
      !email ||
      !year ||
      !make ||
      !model ||
      !consent1 ||
      !consent2 ||
      !consent3
    ) {
      setStatus("error");
      setMessage("Please accept consents and complete information");
      return;
    }

    try {
      const form = new FormData();
      form.append("file", file);

      const user = {
        firstName,
        lastName,
        email,
        year,
        make,
        model,
        consent1,
        consent2,
        consent3,
        imageName: file.name,
        timeStamp: Date.now(),
      };

      await axios
        .post("https://promotions.cokerclassicstarseries.com/api/data", user)
        .then(async (res) => {
          await axios
            .post(
              "https://promotions.cokerclassicstarseries.com/api/uploads",
              form,
              {
                headers: {
                  "Content-type": "multipart/form-data",
                },
              }
            )
            .then((res) => {
              setStatus(res.data.status);
              if (res.data.status === "success") {
                setMessage(
                  "You have successfully uploaded your information and image"
                );
                setFirstName("");
                setLastName("");
                setEmail("");
                setYear("");
                setMake("");
                setModel("");
                setConsent1(false);
                setConsent2(false);
                setConsent3(false);
                setFile(null);
                resetsFileInput();
              } else {
                setMessage(
                  "An error has occured uploading your image and information"
                );
              }
            })
            .catch((err) =>
              setMessage(
                "A system error has occured uploading your information"
              )
            );
        })
        .catch((err) =>
          setMessage("A system error has occured uploading your image")
        );
    } catch (err) {
      console.error(err);
    }
  };

  const resetsFileInput = () => {
    let randomString = Math.random().toString(36);
    setInputKey(randomString);
  };

  return (
    <div className="form__container">
      <form className="form" encType="multipart/form-data">
        <div style={{ height: "2rem" }}>
          {
            <h4 style={{ color: status === "error" ? "red" : "green" }}>
              {message}
            </h4>
          }
        </div>
        <div className="form__input--box">
          <div>
            <h2>User Information</h2>
            <input
              type="text"
              className="form__input"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              placeholder="First Name"
            />
            <input
              type="text"
              className="form__input"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              placeholder="Last Name"
            />
            <input
              type="text"
              className="form__input"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Email"
            />
          </div>
          <div>
            <h2>Vehicle Information</h2>
            <input
              type="text"
              className="form__input"
              onChange={(e) => setYear(e.target.value)}
              value={year}
              placeholder="Year"
            />
            <input
              type="text"
              className="form__input"
              onChange={(e) => setMake(e.target.value)}
              value={make}
              placeholder="Make"
            />
            <input
              type="text"
              className="form__input"
              onChange={(e) => setModel(e.target.value)}
              value={model}
              placeholder="Model"
            />
          </div>
        </div>
        <div className="form__checkbox--box">
          <h3>Terms and Conditions</h3>
          <p className="contest__rules">
            Enter photo contest for a set of all new Coker Classic Star Series
            tires, up to a $1,500.00 and to be featured in the 2025 Coker
            Calendar. Selected winner must have tires mounted at their own
            expense before August 5, 2024, photo due date. Only the original car
            selected for the contest with the Coker Classic Star series tires
            mounted will be featured in the calendar.
          </p>
          <p className="contest__rules">
            No purchase or payment of any kind is necessary to enter or win the
            prize. A purchase does not improve your chances of winning. Odds of
            winning will depend on the total number of eligible entries
            received. Void outside the 48 contiguous United States and the
            District of Columbia, and where prohibited by law. Winner will be
            announced 07/22/2024. Entries must be received 06/30/24 at 11:59 PM.
          </p>
          <p className="contest__rules">
            Enter the information on the form to complete your entry into the
            photo contest giveaway. Coker Tire retains the right to change the
            rules of the contest at any moment, and all vehicles selected will
            be at the sole discretion of Coker Tire. The winner will be required
            to sign a photo usage release agreement.
          </p>
          <div className="form__checkbox">
            <input
              type="checkbox"
              onChange={() => setConsent3(!consent3)}
              checked={consent3}
            />
            <p>
              I agree with the terms listed for the HDC Days Photo
              Contest/Giveaway by Coker Tire.
            </p>
          </div>
          <div className="form__checkbox">
            <input
              type="checkbox"
              onChange={() => setConsent2(!consent2)}
              checked={consent2}
            />
            <p>I consent to be contacted by marketing through email.</p>
          </div>
          <div className="form__checkbox">
            <input
              type="checkbox"
              onChange={() => setConsent1(!consent1)}
              checked={consent1}
            />
            <p>
              I consent for my information & photo to be used in ads, catalog,
              social media and other marketing.
            </p>
          </div>
        </div>
        <div>
          <img
            src={file === null ? "" : URL.createObjectURL(file)}
            alt="user vehicle"
            className="display__image"
            style={{ display: file === null ? "none" : "block" }}
          />
          <input
            onChange={(e) => {
              if (e.target.files[0] !== undefined) setFile(e.target.files[0]);
            }}
            type="file"
            name="file"
            key={inputKey || ""}
            className="button__img"
            accept="image/png, image/jpeg, image/jpg"
          />
        </div>
        <button className="button" onClick={(e) => onSubmit(e)}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default Form;
